import React from "react"
import { LayoutArchivio } from "../components/layout"
//import { GrigliaArchivio } from "../components/griglia"
import Seo from "../components/seo"

const ArchivioPage = () => (
    <LayoutArchivio>
        <Seo title="Archivio" />
    </LayoutArchivio>
)

export default ArchivioPage
